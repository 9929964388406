.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  text-align: center;
}

.message svg {
  color: var(--primary-color);
}

.message p {
  margin-top: 20px;
  color: var(--text-secondary-color);
}

.message a {
  color: var(--text-color);
}
