.root {
  display: none;
}

@media (max-width: 1000px) {
  .root {
    display: block;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(
    35.9deg,
    #000000 -6.17%,
    #000000 67.99%,
    #1c3047 99.29%
  );
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 32px;
  padding: 32px;
}

.content h2 {
  color: white;
}

.content p {
  font-size: 18px;
  color: white;
}

.highlight {
  color: var(--color-acqua);
}

.applyButton,
.applyButton:hover,
.applyButton:active,
.applyButton:focus {
  padding: 10px 18px;
  border-radius: 18px;
  font-size: 14px;
  font-weight: bold;
  background-color: var(--color-acqua);
  color: black !important;
}

.computerImage {
  width: 100%;
  min-height: 200px;
  margin-top: 48px;
  flex-grow: 1;
  background-image: url("../../public/images/mobile-placeholder-computer.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}

@media screen and (min-width: 500px) {
  .content {
    padding: 64px;
  }
}

@media screen and (min-width: 750px) {
  .content {
    padding: 64px 20% 64px 64px;
  }
}
