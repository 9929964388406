@media (max-width: 1000px) {
  .content:not(.allowMobile) {
    display: none;
  }
}

.backButton {
  position: absolute;
  left: 1rem;
  top: 1.375rem;
}

.backButton button {
  background-color: var(--back-button-bg-color);

  transition: background-color 0.2s linear;
}

.backButton button:hover {
  background-color: var(--back-button-hover-bg-color);

  transition: background-color 0.2s linear;
}

.backButton button svg {
  color: var(--back-button-icon-color);
}

@media screen and (max-width: 1000px) {
  .backButton.hideInMobile {
    display: none;
  }
}
